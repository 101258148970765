import React from "react";
import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/Home/HomePage";
import Privacy from "./Pages/Privacy/Privacy";
import Terms from "./Pages/Terms/Terms";
import Footer from "./components/Fotter/Footer";
import AIPolicyStatement from './Pages/AIPolicyStatement/AIPolicyStatement';

function App() {
  return (
    <>
      <div className="dark:bg-[#111920] scroll-smooth">
        <header>
          <Navbar />
        </header>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/ai-policy" element={<AIPolicyStatement/>} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
