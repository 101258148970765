import React from 'react'
import HeroSection from '../../sections/Hero'
import AboutSection from '../../sections/About'
import ProductsSection from '../../sections/Products'
import NewsSection from '../../sections/News'
import FAQSection from '../../sections/FAQ'

function HomePage() {
    return (
        <>
            <HeroSection />
            <AboutSection />
            <ProductsSection />
            <NewsSection />
            {/* <DirectorsSection /> */}
            <FAQSection />
            
        </>
    )
}

export default HomePage
