import React from 'react'

function Terms() {
    return (
        <>
            <div className="pt-[80px] dark:bg-[#111920] about ">
                <div>

                    <div className="flex justify-center">
                        <div className="container 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px]">
                            <div className="2xl:p-4 xl:p-4 lg:p-4 md:p-4 p-6">
                                <p className="text-[40px] font-extralight dark:text-white ">
                                    Terms of Use
                                </p>
                                <p className="text-[20px] font-extralight dark:text-white ">
                                    Effective Date: January 2025
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center ">
                        <div className="container flex justify-center 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px] 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col  mb-5 rounded-lg pt-5">
                            <div className="2xl:m-5 xl:m-5 lg:m-5 md:m-5 sm:m-5 m-4 rounded-[16px] bg-[#cbd5e1] dark:bg-[#222a32] pt-6 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10">
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        1. Introduction
                                    </p>
                                    <p className="2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                        Welcome to the Victoriam Inc. network of services. These Terms of Use ("Terms") form a legal agreement between you ("User," "you," or "your") and Victoriam Inc. ("Victoriam," "we," "us," or "our"), the parent company of products including IzzyAI. These Terms govern your use of our services, whether accessed through a website, mobile application, or any other platform.
                                    </p>
                                    <p className="mt-5 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                        By using our services, you agree to these Terms. If you do not agree, you may not use our services.
                                    </p>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        2. Scope of Services
                                    </p>
                                    <p className="2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                        Victoriam Inc. operates as the parent entity for its products. Each product may have its own terms and conditions, which shall supplement (and take precedence over, where applicable) these Terms. Together, these documents govern your interactions with Victoriam and its subsidiaries.
                                    </p>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        3. User Eligibility
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                    <li className="mb-1 dark:text-white">
                                            <span className=" font-bold  dark:text-white">
                                            Age Restrictions:
                                            </span>
                                            <span className="  dark:text-[#e0e0e0] ml-2 ">
                                            You must be at least 18 years old to use our services unless explicitly stated otherwise. For users under 18 or those lacking capacity, consent from a parent, guardian, or legal representative is required.
                                            </span>
                                        </li>
                                        <li className=" dark:text-white">
                                            <span className=" font-bold  dark:text-white">
                                            Legal Responsibility:
                                            </span>
                                            <span className="  dark:text-[#e0e0e0] ml-2">
                                            By using Victoriam services, you confirm that you have the authority to agree to these Terms.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        4. Account Responsibilities
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                    <li className="mb-1 dark:text-white">
                                            <span className=" font-bold  dark:text-white">
                                            Account Information: 
                                            </span>
                                            <span className="  dark:text-[#e0e0e0] ml-2 ">
                                            You are responsible for providing accurate and up-to-date information during registration. 
                                            </span>
                                        </li>
                                        <li className=" dark:text-white">
                                            <span className=" font-bold  dark:text-white">
                                            Security: 
                                            </span>
                                            <span className="  dark:text-[#e0e0e0] ml-2">
                                            You must maintain the confidentiality of your account credentials. Any activity under your account is your responsibility.
                                            </span>
                                        </li>

                                    </ul>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className="flex 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] mb-3 dark:text-white ">
                                        <span className='font-bold 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px]'>5.</span>
                                        <p className='w-11/12 pl-2'>
                                         <span className='font-bold 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px]'> Use of AI and AI Limitations</span> <span className='2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[20px] text-[15px]'>Victoriam Inc. products, including but not limited to IzzyAI, leverage AI-powered tools to deliver personalized and innovative experiences. By using our services, you acknowledge and agree to the following:</span>
                                        </p>
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0] font-bold'>Purpose:</div>
                                            <div className="  dark:text-[#e0e0e0]">
                                                <ul className="list-decimal ml-5 mt-1">
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        AI systems are designed to assist and enhance user interactions, not to replace professional judgment or expertise.
                                                        </span>
                                                    </li>
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        Outputs may vary as AI models evolve and adapt over time.                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0] font-bold'>Accuracy and Limitations:</div>
                                            <div className="  dark:text-[#e0e0e0]">
                                                <ul className="list-decimal ml-5 mt-1">
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        AI-generated outputs may contain inaccuracies or omissions and should not be solely relied upon for critical decisions.
                                                        </span>
                                                    </li>
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        To improve and refine AI models, anonymized and aggregated data from user interactions may be used.
                                                        </span>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0] font-bold'>Data Use for AI Training:</div>
                                            <div className="  dark:text-[#e0e0e0] ">
                                                <ul className="list-decimal ml-5 mt-1">
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        Data collected during user interactions is essential for powering AI-driven features that are core to the functionality of Victoriam products. This includes providing personalized recommendations, real-time insights, and adaptive experiences.
                                                        </span>
                                                    </li>
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        To improve and refine AI models, anonymized and aggregated data from user interactions may be used.
                                                        </span>
                                                    </li>
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        Personally identifiable information will not be used for AI training or model improvements without your explicit consent, as detailed in our <a href="/privacy" className='underline text-[#638df8]'>Privacy Policy</a>.
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0] font-bold'>Human Oversight Required:</div>
                                            <div className="  dark:text-[#e0e0e0] ">
                                                <ul className="list-decimal ml-5 mt-1">
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        Users are responsible for reviewing and verifying AI-generated recommendations and consulting appropriate professionals when necessary.
                                                        </span>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        6. Intellectual Property
                                    </p>
                                    <p className="2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                        All intellectual property rights related to Victoriam products and services are owned by Victoriam Inc. or its licensors.  
                                    </p>
                                    <p className="mt-4 mb-2 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    You may not:
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Copy, distribute, or create derivative works from our content without prior written permission.</div>
                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Reverse-engineer, decompile, or attempt to access our source code.</div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        7. Payment and Subscriptions
                                    </p>
                                    <p className="2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    Free Trials and Auto-Renewals: By signing up for a free trial, you agree to automatic billing for subscriptions unless canceled before the trial ends.
                                    Refunds: Refund policies are product-specific and detailed in the applicable terms.
                                    </p>
                                </div>

                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4 mb-3">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        8. Limitation of Liability
                                    </p>
                                    <p className="2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    Victoriam Inc. and its subsidiaries disclaim all liability for:
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Indirect, incidental, or consequential damages.</div>
                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Poor outcomes resulting from product use, including healthcare or educational tools.</div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4 mb-3">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        9. Governing Law and Dispute Resolution
                                    </p>
                                    <p className="2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    This Agreement is governed by the laws of Delaware, USA.
                                    </p>
                                    <p className="mt-2 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    Disputes will be resolved through binding arbitration under AAA rules, with proceedings held in Delaware or virtually.
                                    </p>
                                </div>

                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4 mb-2">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        10. Amendments
                                    </p>
                                    <p className="2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    Victoriam Inc. reserves the right to modify these Terms. Users will be notified of changes, which take effect upon posting.
                                    </p>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4 mb-10 ">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        11. Contact Us
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14">
                                        <li className=" dark:text-white">
                                            <ul className="list-decimal ml-4">
                                                <li className=" dark:text-white">
                                                    <p className='text-[17px] dark:text-[#e0e0e0]'><span className='font-bold'>Email:</span> <a href="mailto:info@vtm.ai" className='hover:underline text-[#0000cd]'>info@vtm.ai</a> </p>
                                                </li>
                                                <li className=" dark:text-white">
                                                    <p className='text-[17px] dark:text-[#e0e0e0]'><span className='font-bold'>Address:</span>  8 The Green STE A, Dover, Delaware 19901</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Terms
