import React from 'react'

function Privacy() {
    return (
        <div className="pt-[80px] dark:bg-[#111920] about ">
            <div>
                <div className="flex justify-center">
                    <div className="container 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px]">
                        <div className="2xl:px-4 xl:px-4 lg:px-4 md:px-4 px-6">
                            <p className="text-[40px] font-extralight dark:text-white ">
                                Privacy Policy
                            </p>
                            <p className="text-[20px] font-extralight dark:text-white ">
                                Effective Date: January 2025
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center ">
                    <div className="container flex justify-center 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px] 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col  mb-5 rounded-lg pt-5">
                        <div className="2xl:m-5 xl:m-5 lg:m-5 md:m-5 sm:m-5 m-4 rounded-[16px] bg-[#cbd5e1] dark:bg-[#222a32]  2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 pt-6 pb-5">
                            <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                    1. Introduction
                                </p>
                                <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    Victoriam Inc. and its subsidiaries (collectively, "Victoriam") value your privacy. This Privacy Policy explains how we collect, use, and share data across all Victoriam products and services.
                                </p>
                                {/* <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Personal Information</div>
                                        <div className="  dark:text-[#e0e0e0]  2xl:ml-4 xl:ml-4 lg:ml-4 ">
                                            <ul className="list-decimal  mt-1 ">
                                                <li className="mb-1 dark:text-white">
                                                    <span className=" font-bold  dark:text-white">
                                                        Account Details:
                                                    </span>
                                                    <span className="  dark:text-[#e0e0e0] ml-2 ">
                                                        Name, email address, date of birth, password, and other information you provide during sign-up.
                                                    </span>

                                                </li>
                                                <li className=" dark:text-white">
                                                    <span className=" font-bold  dark:text-white">
                                                        Payment Information:
                                                    </span>
                                                    <span className="  dark:text-[#e0e0e0] ml-2">
                                                        Billing details, credit card information, and transaction history (processed securely by third-party payment providers).
                                                    </span>

                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Usage Data</div>
                                        <div className="  dark:text-[#e0e0e0] ">
                                            <ul className="list-decimal ml-4 mt-1">
                                                <li className="mb-1 dark:text-white">
                                                    <span className=" font-bold  dark:text-white">
                                                        Device Information:
                                                    </span>
                                                    <span className="  dark:text-[#e0e0e0] ml-2">
                                                        IP address, browser type, operating system, and device identifiers.
                                                    </span>
                                                </li>
                                                <li className=" dark:text-white">
                                                    <span className=" font-bold  dark:text-white">
                                                        Activity Data:
                                                    </span>
                                                    <span className="  dark:text-[#e0e0e0] ml-2">
                                                        Features accessed, time spent on the Service, and interactions with the Service.
                                                    </span>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Health-Related Data</div>
                                        <div className="  dark:text-[#e0e0e0] ">
                                            <ul className="list-decimal ml-4 mt-2">
                                                <li className="mb-1 dark:text-white">
                                                    <span className=" font-bold  dark:text-white">
                                                        Optional Inputs:
                                                    </span>
                                                    <span className="  dark:text-[#e0e0e0] ml-2">
                                                        Speech therapy goals, progress logs, or assessments provided by you through the Service.
                                                    </span>
                                                </li>
                                                <li className="mb-1 dark:text-white">
                                                    <span className=" font-bold  dark:text-white">
                                                        AI Interaction Data:
                                                    </span>
                                                    <span className="  dark:text-[#e0e0e0] ml-2">
                                                        Conversations and data inputted during sessions with the AI.
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul> */}
                            </div>
                            <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                    2. Data We Collect
                                </p>
                                <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Personal Information:  </div>
                                        <div className="  dark:text-[#e0e0e0]">
                                            <ul className="list-decimal ml-5 mt-1">
                                                <li className="mb-1 dark:text-white">
                                                    <span className="  dark:text-[#e0e0e0]">
                                                        Name, email, payment details, age, and other identifying information.
                                                    </span>
                                                </li>

                                            </ul>
                                        </div>

                                    </li>
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Sensitive Data:</div>
                                        <div className="  dark:text-[#e0e0e0]">
                                            <ul className="list-decimal ml-5 mt-1">
                                                <li className="mb-1 dark:text-white">
                                                    <span className="  dark:text-[#e0e0e0]">
                                                        Certain products (e.g., IzzyAI) may collect health-related or educational data, subject to explicit consent.
                                                    </span>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Device Data:  </div>
                                        <div className="  dark:text-[#e0e0e0] ">
                                            <ul className="list-decimal ml-5 mt-1">
                                                <li className="mb-1 dark:text-white">
                                                    <span className="  dark:text-[#e0e0e0]">
                                                        IP address, browser type, and other technical details.
                                                    </span>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                    3. How We Use Your Data
                                </p>


                                <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>To provide, improve, and personalize Victoriam products and services.  </div>
                                    </li>
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>For internal analytics and AI model improvement, using anonymized or aggregated data.</div>
                                    </li>
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>To comply with legal requirements.  </div>
                                    </li>

                                </ul>
                            </div>
                            <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                    4. AI Data Processing for Core Functionality
                                </p>
                                <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    Victoriam products, including IzzyAI, rely on artificial intelligence (AI) to deliver core functionalities. These features include personalized experiences, real-time insights, and tailored recommendations.
                                </p>
                                <p className="mt-5 mb-2 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    To ensure the effective operation of our AI-powered tools:
                                </p>

                                <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Data Collection and Analysis:  </div>
                                        <div className="  dark:text-[#e0e0e0]   ">
                                            <ul className="list-decimal ml-4 mt-1 ">
                                                <li className="mb-1 dark:text-white">
                                                    <span className="  dark:text-[#e0e0e0]  ">
                                                    User data is processed during interactions with the product to provide core services, such as personalized therapy recommendations in IzzyAI.  
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Essential Processing:  </div>
                                        <div className="  dark:text-[#e0e0e0] 2xl:ml-4 xl:ml-4 lg:ml-4">
                                            <p className="  dark:text-[#e0e0e0] ml-1">
                                            This data processing is integral to the functionality of Victoriam products and cannot be disabled without affecting the user experience.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Compliance:  </div>
                                        <div className="  dark:text-[#e0e0e0] 2xl:ml-4 xl:ml-4 lg:ml-4">
                                            <p className="  dark:text-[#e0e0e0] ml-1">
                                            All data processed by AI systems complies with applicable laws, including GDPR, CCPA, and HIPAA where relevant.  
                                            </p>
                                        </div>
                                    </li>                                  
                                </ul>
                            </div>
                            <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                    5. Anonymized Data for AI Training
                                </p>
                                <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  mb-3 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                In addition to processing data for core functionalities, Victoriam Inc. may use anonymized and aggregated data to improve AI models. This helps us enhance the accuracy and performance of our services across all products.  
                                </p>
                                <p className="mt-5 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  mb-1 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                This data:  
                                </p>
                                <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Is stripped of any personally identifiable information. </div>
                                        
                                    </li>
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Is securely processed using industry-standard measures.  </div>
                                        
                                    </li>
                                    <li className="mb-3 dark:text-white">
                                        <div className=' dark:text-[#e0e0e0]'>Enables improved features and services for all users. </div>
                                        
                                    </li>                                  
                                </ul>
                            </div>
                            <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4 mb-3">
                                <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                    6. Data Sharing
                                </p>
                                <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                We may share your data:  
                                </p>
                                <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg ">
                                    <li className="mb-1 dark:text-white">
                                        <span className="  dark:text-white">
                                        Within Victoriam Inc. and its subsidiaries.  
                                        </span>
                                    </li>
                                    <li className=" dark:text-white">
                                        <span className=" dark:text-white">
                                        With service providers for operational purposes (e.g., payment processors). 
                                        </span>
                                        
                                    </li>
                                    <li className=" dark:text-white">
                                        <span className="   dark:text-white">
                                        With regulators or authorities as required by law.  
                                        </span>
                                        
                                    </li>
                                </ul>
                            </div>
                            <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4 mb-3">
                                <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                    7. Data Retention
                                </p>
                                <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg ">
                                        <li className="mb-1 dark:text-white">
                                            <span className=" font-bold  dark:text-white">
                                            General Data:
                                            </span>
                                            <span className="  dark:text-[#e0e0e0] ml-2 ">
                                            Personal data will be retained for as long as your account is active or as required by law.  
                                            </span>
                                        </li>
                                        <li className=" dark:text-white">
                                            <span className=" font-bold  dark:text-white">
                                            Health-Related Data:
                                            </span>
                                            <span className="  dark:text-[#e0e0e0] ml-2">
                                            Health-related data will be retained for a minimum of six years in compliance with HIPAA standards.  
                                            </span>
                                        </li>
                                    </ul>
                            </div>
                            <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4 mb-3">
                                <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                    8. International Transfers
                                </p>
                                <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                If data is transferred outside your jurisdiction (e.g., EU to USA), we use safeguards such as Standard Contractual Clauses to protect your rights.  
                                </p>
                               
                            </div>
                            <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                    9. Your Rights
                                </p>
                                <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg ">
                                    <li className="mb-1 dark:text-white">
                                        <span className="  dark:text-white">
                                        Access, modify, or delete your data.  
                                        </span>
                                    </li>
                                    <li className=" dark:text-white">
                                        <span className=" dark:text-white">
                                        Opt out of certain data processing activities, such as AI training or targeted advertising.
                                        </span>
                                        
                                    </li>
                                    <li className=" dark:text-white">
                                        <span className="   dark:text-white">
                                        File a complaint with your local data protection authority.    
                                        </span>
                                        
                                    </li>
                                </ul>
                            </div>
                            <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 p-4">
                                <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                    10. Contact Information
                                </p>
                                <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                For questions, concerns, or requests regarding this Privacy Policy, contact us at:  
                                </p>
                                <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14">
                                    <li className=" dark:text-white">
                                        <p className=' dark:text-[#e0e0e0]'>If you have any questions about this Agreement, please contact us at:</p>
                                        <ul className="list-decimal ml-4">
                                            <li className=" dark:text-white">
                                                <p className=' dark:text-[#e0e0e0]'><span className='font-bold'>Email:</span> <a href="mailto:info@vtm.ai" className='underline text-[#638df8]'>info@vtm.ai</a> </p>
                                            </li>
                                            <li className=" dark:text-white">
                                                <p className=' dark:text-[#e0e0e0]'><span className='font-bold'>Address:</span>  8 The Green STE A, Dover, Delaware 19901</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy
