import React from 'react'

function AIPolicyStatement() {
    return (
        <>
            <div className="pt-[80px] dark:bg-[#111920] about ">
                <div>
                    <div className="flex justify-center">
                        <div className="container 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px]">
                            <div className="2xl:px-4 xl:px-4 lg:px-4 md:px-4 px-6">
                                <p className="text-[40px] font-extralight dark:text-white ">
                                    AI Policy Statement
                                </p>
                                <p className="text-[20px] font-extralight dark:text-white ">
                                    Effective Date: January 2025
                                </p>
                                <p className=" mt-4 text-lg dark:text-[#e0e0e0]">
                                    At Victoriam Inc., we are committed to harnessing the power of artificial intelligence (AI) to deliver innovative, effective, and user-friendly solutions across our suite of products. This AI Policy Statement outlines our approach to the ethical, responsible, and transparent use of AI in all Victoriam services.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center ">
                        <div className="container flex justify-center 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px] 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col  mb-5 rounded-lg pt-5">
                            <div className="2xl:m-5 xl:m-5 lg:m-5 md:m-5 sm:m-5 m-4 rounded-[16px] bg-[#cbd5e1] dark:bg-[#222a32]  2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 pt-6 pb-5">
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        1. Purpose of AI in Victoriam Products
                                    </p>
                                    <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    AI technologies are at the core of many Victoriam products, enabling personalized, efficient, and scalable solutions. From healthcare support tools to learning platforms, AI helps us:
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Deliver tailored experiences based on user needs.</div>
                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Continuously improve product effectiveness through data-driven insights.</div>
                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Support users in achieving their goals with minimal friction.</div>
                                        </li>

                                    </ul>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        2. Ethical Principles
                                    </p>
                                    <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    Victoriam Inc. adheres to the following principles to guide the use of AI in our products:
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Transparency:    </div>
                                            <div className="  dark:text-[#e0e0e0]">
                                                <ul className="list-decimal ml-5 mt-1">
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        We are clear about how AI is used in our products, providing users with understandable information about its functionality and impact.
                                                        </span>
                                                    </li>

                                                </ul>
                                            </div>

                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Privacy and Security:  </div>
                                            <div className="  dark:text-[#e0e0e0]">
                                                <ul className="list-decimal ml-5 mt-1">
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        User data is safeguarded through robust security measures and used in compliance with applicable laws, including GDPR, CCPA, and HIPAA where relevant.
                                                        </span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Fairness:   </div>
                                            <div className="  dark:text-[#e0e0e0] ">
                                                <ul className="list-decimal ml-5 mt-1">
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        We strive to minimize bias in our AI systems and ensure equitable treatment for all users, regardless of demographics or other characteristics.
                                                        </span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Human Oversight:  </div>
                                            <div className="  dark:text-[#e0e0e0] ">
                                                <ul className="list-decimal ml-5 mt-1">
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        Our AI tools are designed to support, not replace, human decision-making. Users retain ultimate control over decisions informed by AI outputs.
                                                        </span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        3. AI Data Processing for Core Functionality
                                    </p>
                                    <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    Victoriam products, including IzzyAI, rely on artificial intelligence (AI) to deliver core functionalities. These features include personalized experiences, real-time insights, and tailored recommendations. 
                                    </p>
                                    <p className="mt-4 mb-1 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    To ensure the effective operation of our AI-powered tools:
                                    </p>

                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Data Collection and Analysis:    </div>
                                            <div className="  dark:text-[#e0e0e0]">
                                                <ul className="list-decimal ml-5 mt-1">
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        User data is processed during interactions with the product to provide core services, such as personalized therapy recommendations in IzzyAI.  
                                                        </span>
                                                    </li>

                                                </ul>
                                            </div>

                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Essential Processing:  </div>
                                            <div className="  dark:text-[#e0e0e0]">
                                                <ul className="list-decimal ml-5 mt-1">
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        This data processing is integral to the functionality of Victoriam products and cannot be disabled without affecting the user experience.
                                                        </span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Compliance:    </div>
                                            <div className="  dark:text-[#e0e0e0] ">
                                                <ul className="list-decimal ml-5 mt-1">
                                                    <li className="mb-1 dark:text-white">
                                                        <span className="  dark:text-[#e0e0e0]">
                                                        All data processed by AI systems complies with applicable laws, including GDPR, CCPA, and HIPAA where relevant.  
                                                        </span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        4. Data Use in AI Development
                                    </p>
                                    <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    To improve and refine our AI systems, Victoriam Inc. may use anonymized and aggregated user data:
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Data used for AI training is stripped of personally identifiable information (PII).</div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        5. AI Limitations
                                    </p>
                                    <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  mb-3 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    While we strive for high accuracy and effectiveness, AI systems are not perfect and may:
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg">
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Provide inaccurate or incomplete results due to the inherent nature of probabilistic models.</div>
                                        </li>
                                        <li className="mb-3 dark:text-white">
                                            <div className=' dark:text-[#e0e0e0]'>Require human judgment and discretion for critical decisions or actions.</div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4 mb-3">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        6. Accountability
                                    </p>
                                    <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    Victoriam Inc. regularly audits its AI models to:
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14 mt-1  text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg ">
                                        <li className="mb-1 dark:text-white">
                                            <span className="  dark:text-white">
                                            Ensure compliance with ethical standards.
                                            </span>
                                        </li>
                                        <li className=" dark:text-white">
                                            <span className=" dark:text-white">
                                            Identify and address potential biases or inaccuracies.
                                            </span>

                                        </li>
                                        <li className=" dark:text-white">
                                            <span className="   dark:text-white">
                                            Provide ongoing improvements to user experiences.
                                            </span>

                                        </li>
                                    </ul>
                                </div>
                                
                                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 p-4">
                                    <p className=" 2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[20px] font-bold mb-3 dark:text-white">
                                        7. Contact Us
                                    </p>
                                    <p className=" 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5 text-[15px] md:text-[15px] lg:text-lg xl:text-lg 2xl:text-lg 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-4 leading-normal  dark:text-[#e0e0e0]">
                                    For questions or concerns about our AI systems, contact us at:  
                                    </p>
                                    <ul className="list-disc ml-8 2xl:ml-16 xl:ml-14 lg:ml-14 md:ml-14">
                                        <li className=" dark:text-white">
                                            <ul className="list-decimal ml-4">
                                                <li className=" dark:text-white">
                                                    <p className=' dark:text-[#e0e0e0]'><span className='font-bold'>Email:</span> <a href="mailto:info@vtm.ai" className='underline text-[#638df8]'>info@vtm.ai</a> </p>
                                                </li>
                                                <li className=" dark:text-white">
                                                    <p className=' dark:text-[#e0e0e0]'><span className='font-bold'>Address:</span>  Victoriam Inc., 8 The Green STE A, Dover, Delaware 19901</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AIPolicyStatement
