import DarkLogo from "../../Assets/LogoDark";
import "./Footer.css";

function Footer() {
  return (
    <footer className="main-footer text-gray-400 bg-black body-font mt-10 md:mt-32 flex flex-col items-center ">
      <div className="pt-10">
        <DarkLogo />
      </div>

      <div className="container px-10 md:px-32 py-8 mx-auto justify-content-center">
        <div className="container px-10  md:px-32 py-8 mx-auto">
          <div className="flex flex-wrap md:text-left text-center -mb-10 -mx-4">
            <div className="lg:w-1/3 md:w-1/3 w-full px-4 order-1">
              <a
                href="#products"
                className="title-font font-medium text-white tracking-widest text-sm mb-4 flex justify-center items-center col-lg-4"
              >
                Product
              </a>
            </div>
            
            <div className="lg:w-1/3 md:w-1/3 w-full px-4 2xl:order-3 xl:order-3 lg:order-3 md:order-3 order-2">
              <a
                href="#Faq"
                className="title-font font-medium text-white tracking-widest text-sm mb-3 flex justify-center items-center col-lg-4"
              >
                FAQ
              </a>
            </div>
            <div className="lg:w-1/3 md:w-1/3 w-full flex justify-center relative 2xl:order-2 xl:order-2 lg:order-2 md:order-2 order-3">
            <div className="container flex justify-center">
              <div className="footer-drop">
                <input id="toggle" type="checkbox" />
                  <h2 className="text-sm text-white font-medium">Legal</h2>
                <ul>
                  <li className="text-sm hover:underline font-medium tracking-widest"><a href="/privacy" >Privacy Policy</a></li>
                  <li className="text-sm hover:underline font-medium tracking-widest"><a href="/terms" >Terms</a></li>
                  <li className="text-sm hover:underline font-medium tracking-widest"><a href="/ai-policy" >AI Policy Statement</a></li>
                </ul>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div className=" w-full h-px max-w-5xl mx-auto my-1 bg-[#262626]"></div>

      <p className="text-gray-400 text-sm text-center sm:text-left md:text-center px-10 md:px-32 py-5">
        Copyright ©2024 Victoriam Inc.- All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
